import $ from 'jquery';
import { KEYCODES } from '@/scripts/helpers/constants';

export const accordion = mode => {
  const panelTitle = $('.accordion__title');
  const visibilityMode = mode || 'single';
  let transitionTime = 170;

  if (
    navigator.userAgent.match(/Trident.*rv:11\./) ||
    /Edge/.test(navigator.userAgent)
  ) {
    transitionTime = 0;
  }

  function hidePanels() {
    const activeTitles = panelTitle.filter(function() {
      return $(this).attr('aria-selected') === 'true';
    });

    $.each(activeTitles, function() {
      $(this).parent().attr('aria-selected', 'false');
      $(this).attr('aria-selected', 'false');
      $(this).parent().attr('aria-selected', 'false');
      $(this)
        .next()
        .slideUp(transitionTime);
    });
  }

  // Expand or collapse panels
  $.each(panelTitle, function() {
    const isOpen = $(this).attr('aria-selected');

    if (isOpen === 'true') {
      $(this)
        .next()
        .slideDown(transitionTime);
    } else {
      $(this)
        .next()
        .slideUp(transitionTime);
    }
  });

  if (visibilityMode === 'single') {
    panelTitle.on('click keyup', function(e) {
      if (e.type === 'click' || e.keyCode === KEYCODES.ENTER) {
        const title = $(this);
        const panel = $(this).next();

        if (panel.length) {
          if (panel.is(':visible')) {
            $.each(panelTitle, function() {
              hidePanels();
            });
          } else {
            hidePanels();
            title.attr('aria-selected', 'true');
            title.parent().attr('aria-selected', 'true');
            panel.slideDown(transitionTime);
          }
        }
      }
    });
  } else if (visibilityMode === 'multiply') {
    panelTitle.on('click keyup', function(e) {
      if (e.type === 'click' || e.keyCode === KEYCODES.ENTER) {
        const panel = $(this).next();

        if (panel.length) {
          panel.slideDown(transitionTime);
        } else {
          panel.slideUp(transitionTime);
        }
      }
    });
  }
};
